import { SetupContext, Ref, computed, unref } from '@nuxtjs/composition-api'
import { MaybeRef } from '@vueuse/core'
import CustomSelectItem from '@/interfaces/CustomSelectItem'

export default function (
  value: Ref,
  list: MaybeRef<CustomSelectItem[]>,
  { emit }: SetupContext
) {
  const proxyValue = computed({
    get: () => unref(value),
    set: value => emit('input', value),
  })

  // 目前選中的項目文字
  const selection = computed(() => {
    return unref(list).find(item => item.value === unref(value))?.name
  })

  return {
    list,
    proxyValue,
    selection,
  }
}
