




























import SearchBarFieldDate from '@/components/search-bar/SearchBarFieldDate.vue'
import { defineComponent, toRefs, ref, watch, computed } from '@nuxtjs/composition-api'
import dayjs from 'dayjs'

import useDateHourField, { props } from '@/composables/use-date-hour-field'

import DateHour from '@/classes/DateHour'

export default defineComponent({
  props,
  setup (props, { emit }) {
    const dateField = ref<InstanceType<typeof SearchBarFieldDate> | null>(null)
    const minDate = DateHour.getMinDateHour(dayjs(), props.locationShortname === 'US')

    // 獲得時間區間
    const computedHourRange = computed<[number, number]>(() => {
      let defaultHourRange = props.hourRange

      if (props.hourRange[0] !== DateHour.hourRange[0] || props.hourRange[1] !== DateHour.hourRange[1]) { // 如果store有特別設定時間區間  則使用store的特殊時間區間
        return [...props.hourRange]
      }

      switch (props.locationShortname) {
        case 'US':
          // 美國租車時間區間，直接回傳預設值
          return [...DateHour.USHourRange]
        default:
          // 預設租車時間區間，留著做後續時間段排除處理
          defaultHourRange = [...DateHour.hourRange]
      }
      // 把原先的minDate.hour + 1 改成 minDate.hour，時區的邏輯都在dateHour class 裡面處理了
      if (props.selectingValue && props.selectingValue.dateString === minDate.dateString) {
        return [Math.max(minDate.hour, defaultHourRange[0]), defaultHourRange[1]] as [number, number]
      }
      return defaultHourRange
    })

    watch(dateField, (val) => {
      emit('update:dateFieldRef', val)
    })

    watch(computedHourRange, (val) => {
      emit('hour-range-change', val)
    }, { immediate: true })

    return {
      dateField,
      computedHourRange,
      ...useDateHourField({ ...toRefs(props), emit }),
    }
  },
})
