import { computed, PropType, Ref } from '@nuxtjs/composition-api'
import { Dayjs } from 'dayjs'

export const props = {
  value: {
    type: Dayjs as PropType<Dayjs | null>,
    default: null,
  },
  selectingValue: {
    type: Dayjs as PropType<Dayjs | null>,
    default: null,
  },
  active: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: null,
  },
  icon: {
    type: Boolean,
    default: true,
  },
  arrow: {
    type: Boolean,
    default: true,
  },
}

export default function (value: Ref<Dayjs | null>) {
  const selection = computed(() => value.value?.format('MM-DD'))

  return {
    selection,
  }
}
