import MapLocation from '@/classes/MapLocation'

const key = 'location-picker-storage'
const keyReturn = 'location-picker-storage-return'

/** 從 localStorage 取用已儲存的取車地點資訊 */
export function get () {
  try {
    const json = localStorage.getItem(key)
    if (json) return MapLocation.fromJson(json)
  } catch (e) {
  }
}

/** 將取車地點資訊儲存至 localStorage */
export function set (location: MapLocation) {
  try {
    localStorage.setItem(key, location.json)
  } catch (e) {
  }
}

/** 從 localStorage 取用已儲存的還車地點資訊 */
export function getReturnLocation () {
  try {
    const json = localStorage.getItem(keyReturn)
    if (json) return MapLocation.fromJson(json)
  } catch (e) {
  }
}

/** 將還車地點資訊儲存至 localStorage */
export function setReturnLocation (location: MapLocation) {
  try {
    localStorage.setItem(keyReturn, location.json)
  } catch (e) {
  }
}
