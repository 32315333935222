
































import { computed, defineComponent, ref, toRefs } from '@nuxtjs/composition-api'
import GSelectMenu from '@/components/GSelectMenu.vue'
import useHourField, { props } from '@/composables/use-hour-field'
import useBreakpoint from '@/composables/use-breakpoint'

export default defineComponent({
  props,
  setup (props, context) {
    const { match } = useBreakpoint()
    const isMobile = !match('xl')

    const showCustomizedSelect = computed(() => !isMobile)

    const selectMenu = ref<InstanceType<typeof GSelectMenu> | null>(null)

    const showMenu = () => {
      selectMenu.value?.show()
    }

    const buttonClass = computed(() => ({
      'no-arrow': !props.arrow,
      'justify-end xl:justify-start': true,
    }))

    return {
      ...useHourField(
        toRefs(props).value,
        toRefs(props).range,
        toRefs(props).min,
        context
      ),
      selectMenu,
      showMenu,

      showCustomizedSelect,
      buttonClass,
    }
  },
})
