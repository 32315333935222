







import { computed, defineComponent } from '@nuxtjs/composition-api'
import useDateField, { props } from '@/composables/use-date-field'

export default defineComponent({
  props,
  setup (props) {
    const value = computed(() => props.selectingValue ?? props.value)

    const buttonClass = computed(() => ({
      'no-arrow': !props.arrow,
    }))

    return {
      ...useDateField(value),
      buttonClass,
    }
  },
})
