







import { computed, defineComponent, nextTick, onMounted, onUnmounted, ref, watch } from '@nuxtjs/composition-api'
import { createPopper, Instance } from '@popperjs/core'

export default defineComponent({
  props: {
    reference: null,
    value: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    let instance: Instance

    const popper = ref<HTMLElement>()
    const arrow = ref<HTMLElement>()

    const target = computed(() => props.reference?.$el ?? props.reference)

    const create = () => {
      instance = createPopper(
        target.value as HTMLElement,
        popper.value as HTMLElement,
        {
          placement: 'bottom-start',
          strategy: 'fixed',
          modifiers: [
            {
              name: 'arrow',
              options: {
                element: arrow.value,
                padding: 5,
              },
            },
            {
              name: 'offset',
              options: {
                offset: [0, 10],
              },
            },
          ],
        }
      )
    }

    const destroy = () => instance?.destroy?.()

    onMounted(() => {
      watch(
        () => [props.value, target.value],
        async () => {
          await nextTick()
          destroy()
          await nextTick()
          if (props.value && target.value) create()
        },
        { immediate: true }
      )
    })

    onUnmounted(destroy)

    return {
      popper,
      arrow,
    }
  },
})
