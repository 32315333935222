import { computed, PropType, Ref, SetupContext } from '@nuxtjs/composition-api'
import { Dayjs } from 'dayjs'

import DateHour from '@/classes/DateHour'

import Country from '@/enums/Country'
import StartEnd from '@/enums/StartEnd'
import * as storage from '@/modules/location-picker-storage'

export const props = {
  label: {
    type: String,
    default: '',
  },
  hideLabel: {
    type: Boolean,
    default: false,
  },
  value: {
    type: DateHour,
    default: () => new DateHour(),
  },
  selectingValue: {
    type: DateHour,
    default: () => new DateHour(),
  },
  active: {
    type: Boolean,
    default: false,
  },
  hourRange: {
    type: Array as unknown as PropType<[number, number]>,
    default: () => DateHour.hourRange,
  },
  /** 描述這個組件目前是用在「取車」或「還車」 */
  type: {
    type: Number as PropType<StartEnd | null>,
    default: null,
  },
  hourErrorMessage: {
    type: String,
    default: null,
  },
  locationShortname: {
    type: String || null,
    default: () => storage.get()?.country || Country.TAIWAN,
  },
  dateIcon: {
    type: Boolean,
    default: true,
  },
  dateArrow: {
    type: Boolean,
    default: true,
  },
  hourIcon: {
    type: Boolean,
    default: false,
  },
  hourArrow: {
    type: Boolean,
    default: true,
  },
}

export default function ({ value, selectingValue, type: _type, emit }: {
  value: Ref<DateHour>,
  selectingValue: Ref<DateHour>,
  type: Ref<StartEnd | null>,
  emit: SetupContext['emit'],
}) {
  const proxyDate: Ref<Dayjs | null> = computed({
    get: () => value.value.date,
    set: value => emit('change', new DateHour(value, proxyHour.value)),
  })

  const proxyHour: Ref<number> = computed({
    get: () => value.value.hour,
    set: value => emit('change', new DateHour(proxyDate.value, value)),
  })

  const selectingDate = computed(() => selectingValue.value.date)

  const minHour = computed(() => {
    const min = DateHour.getMinDateHour()
    // 若選擇的日期和 min 同一天
    if (proxyDate.value && min.date?.isSame(proxyDate.value)) {
      return min?.hour
    }
  })

  const clickDate = () => {
    emit('click-date')
  }
  const clickHour = () => {}

  return {
    proxyDate,
    proxyHour,
    selectingDate,
    minHour,
    clickDate,
    clickHour,
  }
}
