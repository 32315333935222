import { computed, PropType, ref, Ref, SetupContext, unref, watch } from '@nuxtjs/composition-api'
import useCustomSelect from '@/composables/use-custom-select'
import DateHour from '@/classes/DateHour'
import { isNumber } from 'typechecker'

export const props = {
  value: {
    type: Number,
    default: 10,
  },
  range: {
    type: Array as unknown as PropType<[number, number] | Array<[number, number]>>,
    default: () => DateHour.hourRange,
  },
  min: {
    type: Number as PropType<number | null>,
    default: null,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  icon: {
    type: Boolean,
    default: false,
  },
  arrow: {
    type: Boolean,
    default: true,
  },
}

export default function (
  value: Ref<number>,
  range: Ref<[number, number] | Array<[number, number]>>,
  min: Ref<number | null>,
  context: SetupContext,
) {
  /** 小時數列表 */
  const hours = computed(() => {
    const hours: Array<number> = []

    if (range.value[0] === undefined) return hours

    let min = 0; let max = 24

    if (isNumber(range.value[0])) {
      min = range.value[0] as number
      max = range.value[1] as number
    } else {
      min = (range.value[0] as [number, number])[0]
      max = (range.value[range.value.length - 1] as [number, number])[1]
    }

    for (let i = min; i <= max; i++) hours.push(i)
    return hours
  })

  /** 不可預定的小時數 Set */
  const disabledHoursSet = computed(() => {
    /** 非 Hertz */
    if (isNumber(range.value[0]) || !range.value[0] || (range.value[0] as Array<number>).length === 0) return new Set<number>()

    /** Hertz */
    const hoursSet = new Set<number>()

    const _range = range.value as Array<[number, number]>

    for (let i = 0; i < _range.length - 1; i++) {
      const curr = _range[i]
      const next = _range[i + 1]
      if (!next) continue

      for (let j = curr[1] + 1; j < next[0]; j++) {
        hoursSet.add(j)
      }
    }
    return hoursSet
  })

  /** 小時數選單列表 */
  const options = computed(() => {
    return unref(hours).map((value) => {
      const paddedHour = `${value}`.padStart(2, '0')
      const name = `${paddedHour}:00`
      const disabled = getIsDisabled(value, disabledHoursSet.value)
      return { name, value, disabled }
    })
  })

  const getIsDisabled = (hour: number, disabledHoursSet: Set<number>): boolean => {
    if (disabledHoursSet.has(hour)) return true
    if (min.value == null) return false
    return hour < min.value
  }

  const {
    list,
    proxyValue,
    selection,
  } = useCustomSelect(value, options, context)

  /** 調整時間欄位選中的項目。若超出允許範圍，就將時間設成 `min` 或 `max` 值 */
  const normalize = () => {
    let [min, max] = range.value

    // 來自 Hertz range.value 為 [number, number][]
    if (typeof min === 'object' || typeof max === 'object') {
      min = Math.min(...range.value.flat())
      max = Math.max(...range.value.flat())
    }

    if (min > proxyValue.value) proxyValue.value = min
    else if (proxyValue.value > max) proxyValue.value = max
  }
  const target = ref<HTMLElement>()
  watch(range, normalize, { immediate: true })

  return {
    list,
    proxyValue,
    selection,
    getIsDisabled,
    normalize,
    target,
  }
}
